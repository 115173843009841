import React, { useEffect, useState } from "react";
import { Box, Button, Divider, IconButton, Input, InputBase, LinearProgress, ListItem, ListItemIcon, ListItemText, MenuItem, MenuList, Stack, TextField, Typography } from "@mui/material";
import AnimatedModal from "../Shared/AnimatedModal";
import { Axios } from "axios";
import { useRequests } from "../../contexts/RequestContext";
import { useApi } from "../../contexts/ApiProvider";
import { Search } from "@mui/icons-material";
import Link from '@mui/material/Link';



export const AppsList = () => {
    
    const {fakeFetch, authenticatedFetch} = useRequests();
    const {apps, loadApps} = useApi();

    useEffect(() => {
        loadApps();
    },[]);

    const [searchTerm, setSearchTerm] = useState("");

    const [lookupError, setLookupError] = useState(null);

    return (
        <MenuList>
            {/* <Stack spacing={2} direction={'row'} alignItems={'center'}>
                <InputBase
                    sx={{ ml: 1, flex: 1 }}
                    placeholder="Filter apps..."
                    inputProps={{ 'aria-label': 'search google maps' }}
                />
                <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                    <Search />
                </IconButton>
                <Divider/>
                </Stack> */}
            {
                apps.map((app) => {
                    return (
                        <MenuItem onClick={() => window.open(app.url, '_blank')}  rel="noopener noreferrer" key={app.id}>
                            <ListItemIcon>
                                <Box component="img" src={app.icon_url} sx={{ width: '50px', height: '50px' }} />
                            </ListItemIcon>
                            <ListItemText>{app.name}</ListItemText>
                        </MenuItem>

                    )
                })
            }
            {
                apps.length == 0 &&
                <ListItemText><Typography variant="body2" color="text.secondary">No apps assigned to your organization</Typography></ListItemText>
            }
        </MenuList>
    )
};

export default AppsList;