import React, { createContext, useContext, useEffect, useState, useMemo } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { Snackbar } from '@mui/material';
import { useRequests } from './RequestContext';

const ApiContext = createContext();

const defaultDocumentFilters = {
  searchTerm: '',
  personalOnly: false
}

export const ApiProvider = ({ children, apiBase = "" }) => {

  const { user, isAuthenticated } = useAuth0();
  const {authenticatedFetch} = useRequests();
  // Information about the user's stuff

  const [profile, setProfile] = useState(null);
  
  const [orgInfo, setOrgInfo] = useState({
    organizations: [],
    currentOrganization: null,
    apps: []
  });

  const orgInfoMemo = useMemo(() => orgInfo, [orgInfo]);

  const [documentFilters, setDocumentFilters] = useState(defaultDocumentFilters);

  const [documents, setDocuments] = useState([]);
  const [reports, setReports] = useState([]);
  const [apps, setApps] = useState([]);

  const [blockingLoad, setBlockingLoad] = useState(false);

  const [messages, setMessages] = useState([
    // {severity: "error", message: "Could not authenticate"},
    // {severity: "success", message: "Could not authenticate"} 
  ]);

  const loadProfile = () => {
    authenticatedFetch('/api/account')
    .then( (d) => {
      
      setProfile(d.data)
    })
    .catch(
      e => setMessages([...messages, {severity: "error", message: "Could not load or initialize profile"}])
    )
  }

  const loadApps = () => {
    authenticatedFetch('/api/organizations/current/apps')
    .then( (d) => {
      
      setApps(d.data)
    })
    .catch(
      e => setMessages([...messages, {severity: "error", message: "Could load apps"}])
    )
  }

  const loadDocuments = () => {
    authenticatedFetch('/api/organizations/current/documents')
    .then( (d) => {
      
      setDocuments(d.data)
    })
    .catch(
      e => setMessages([...messages, {severity: "error", message: "Could not load documents"}])
    )
  }

  const loadReports = () => {
    authenticatedFetch('/api/organizations/current/reports')
    .then( (d) => {
      
      setReports(d.data)
    })
    .catch(
      e => setMessages([...messages, {severity: "error", message: "Could not load reports"}])
    )
  }



  const loadCurrentOrgs = () => {
    authenticatedFetch('/api/account/organizations')
    .then( (d) => {
      
      setOrgInfo({...orgInfo, organizations: d.data})
    })
    .catch(
      e => {
        console.error("Couldn't load org info", e)
        setMessages([...messages, {severity: "error", message: "Could not load your organizations"}]);
      }
    )
  }

  const findOrgByShareCodeAsync = (shareCode) => {
    authenticatedFetch(`/api/organizations/find/${shareCode}`)
  }

  useEffect(() => {
    if(isAuthenticated) {
      
      loadProfile();
      if(profile?.id) {
        loadCurrentOrgs();
      }
      if(profile?.current_organization_id) {
        loadDocuments()
        loadReports();
      }
    } else {
      
    }
  }, [isAuthenticated, profile?.id]);

  const handleClose = (index) => (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setMessages(messages.filter((msg, i) => i !== index));
  };

  const getOrgInfo = () => {
    authenticatedFetch('/api/account/organizations')
      .then( d => setOrgInfo({...orgInfo, organizations: d.data}))
      .catch( e => console.error(e))
  }

  const attemptToJoinOrg = (orgCode) => {
    authenticatedFetch(`/api/account/organizations/join/${orgCode}`, {method:'POST'})
    .then( d => setOrgInfo({...orgInfo, organizations: d.data}))
    .catch( e => console.error(e))
  }

  const updateProfile = () => {

  }

  return (
    <ApiContext.Provider value={{ 
      orgInfo,
      orgInfoMemo, // Use this if there are issues
      setOrgInfo,

      profile,

      loadProfile,
      loadCurrentOrgs,
      loadApps,
      documents,
      apps,
      reports,
      

      // Document Management 
      documentFilters,
      setDocumentFilters
      
    }}>
      {children}

      {/* Error & Message bar */} 
      {
        messages.map((error, index) => {
          return (
            <Snackbar
              key={index}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              open={true}
              autoHideDuration={6000}
              onClose={handleClose(index)}
              message={error.message}
              severity={error.severity}
            />
          )
        })
      }
      
    </ApiContext.Provider>
  );
};

export const useApi = () => useContext(ApiContext);

