import React, { createContext, useContext, useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';

const RequestContext = createContext();

export const RequestProvider = ({ children, apiBase = "" }) => {

  const { getAccessTokenSilently, user, isAuthenticated } = useAuth0();
  
  const [loading, setLoading] = useState(true);
  const [token, setToken] = useState(null);

  useEffect(() => {
    const fetchToken = async () => {
      if (isAuthenticated) {
        try {
          const accessToken = await getAccessTokenSilently();
          setToken(accessToken);
        } catch (error) {
          console.error('Failed to get access token:', error);
        }
      }
    };

    fetchToken();
  }, [getAccessTokenSilently, isAuthenticated]);

  useEffect(() => {
    
    const fetchToken = async () => {
      
      if (isAuthenticated) {
        try {
          
          const accessToken = await getAccessTokenSilently();
          setToken(accessToken);
        } catch (error) {
          console.error('Failed to get access token:', error);
        }
      } else {
        
      }
    };
  
    fetchToken();
  }, [getAccessTokenSilently, isAuthenticated, user]);


  const fakeFetch = (url, data, options = {}) => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(data);
      }, 1500)
    });
  }
  
  const authenticatedFetch = async (url, options = {}) => {
    if (!isAuthenticated) {
      throw new Error("User is not authenticated");
    }
    try {
      const token = await getAccessTokenSilently();  // Wait for the token
      const response = await axios(apiBase + url, {
        ...options,
        headers: {
          ...options.headers,
          Authorization: `Bearer ${token}`,
        }
      });
      
      return response;  // Return the full response
    } catch (error) {
      console.error("Request failed", error);
      throw new Error("Request failed", error);  // Throw an error if it fails
    }
  };

  const fetch = (url, options = {}) => {
    const path = apiBase+url;
    return axios(path, {
      ...options,
      headers: {
        ...options.headers
      },
    });
  };

  return (
    <RequestContext.Provider value={{ fakeFetch, token, authenticatedFetch, fetch, user, isAuthenticated, loading }}>
      {children}
    </RequestContext.Provider>
  );
};

export const useRequests = () => useContext(RequestContext);

