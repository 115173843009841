import React, { useEffect, useState, useMemo } from "react";
import { Box, Button, Divider, Pagination, IconButton,Grid, List, Input, ListItemButton,InputBase, LinearProgress, ListItem, ListItemIcon, ListItemText, MenuItem, MenuList, Stack, TextField, Typography, ButtonGroup } from "@mui/material";
import AnimatedModal from "../Shared/AnimatedModal";
import { Axios } from "axios";
import { useRequests } from "../../contexts/RequestContext";
import { useApi } from "../../contexts/ApiProvider";
import { Link } from "react-router-dom";
import { Search, ArrowRight } from "@mui/icons-material";
import HumanTimestamp from "../../HumanTimestamp";


export const DocumentsList = () => {
    const resultsOnPage = 5;
    const isLoading = false;
    const {fakeFetch, authenticatedFetch} = useRequests();
    const {documents} = useApi();
    const {orgInfo, profile} = useApi();

    const [searchTerm, setSearchTerm] = useState("");
    const [currentPage, setCurrentPage] = useState(0);
    const [lookupError, setLookupError] = useState(null);

    const [currentFilter, setCurrentFilter] = useState('mine');

    const filteredDocuments = useMemo(() => documents.filter((d) => {
        return d.name.toLowerCase().includes(searchTerm.toLowerCase());
    }), [documents, searchTerm, currentFilter]);

    return (
        <MenuList>
            
            <Stack direction={'row'} spacing={2} alignItems={'center'}>
                <ButtonGroup color="secondary" variant="outlined" aria-label="Basic button group">
                    <Button onClick={() => setCurrentFilter('mine')} variant={currentFilter == 'mine' ? 'contained' : 'outlined'}>My Docs</Button>
                    <Button onClick={() => setCurrentFilter('all')} variant={currentFilter == 'all' ? 'contained' : 'outlined'}>All</Button> 
                </ButtonGroup>
                <Search/>
                <InputBase
                    onChange={(e) => {
                        setSearchTerm(e.target.value);
                        setCurrentPage(0);
                    }}
                    value={searchTerm}
                    sx={{ ml: 1, flex: 1 }}
                    placeholder="Filter documents..."
                    inputProps={{ 'aria-label': 'search google maps' }}
                />
            </Stack>
        
            
        
            <Divider/>
            {
                documents.length > 0 && isLoading == false &&
                <>
                    <List style={{marginTop: 0}}>
                        {
                            filteredDocuments.slice(currentPage*resultsOnPage, (currentPage+1)*resultsOnPage).map( (d) => {
                                const url = `${d.service_app?.url}/#${d.uuid}`;
                                return (
                                    <ListItem
                                        style={{cursor:'pointer'}}
                                        onClick={() => window.open(url, '_blank')}
                                        disablePadding  rel="noopener noreferrer" key={d.service_app?.id}>
                                        <ListItemIcon>
                                            <ListItemIcon>
                                                <Box component="img" src={d.service_app?.icon_url} sx={{ width: '50px', height: '50px' }} />
                                            </ListItemIcon>
                                        </ListItemIcon>
                                        <ListItemText primary={d.name} secondary={
                                            <Box>
                                                <Stack direction="row" spacing={1}>
                                                    <Typography variant="body2" color="text.secondary">
                                                        <HumanTimestamp mysqlTimestamp={d.created_at}/>
                                                    </Typography>
                                                    {
                                                        profile.id === d.created_by_user_id && 
                                                        <>
                                                            <ArrowRight />
                                                            <Typography variant="body2" color="text.secondary">
                                                                {/* {d.created_by_user_id === profile?} */}
                                                                Your Document
                                                            </Typography>
                                                        </>
                                                    }
                                                </Stack>
                                            </Box>
                                            } />
                                    </ListItem>
                                )
                            })
                        }

                    </List>
                    {
                        filteredDocuments.length > resultsOnPage &&
                            <Box justifyItems={'center'}>
                                <Stack spacing={2} alignItems="center">
                                    <Pagination page={currentPage+1} count={(Math.ceil(filteredDocuments.length/resultsOnPage))} color="secondary" onChange={(e, val) => setCurrentPage(val-1)} />
                                </Stack>
                            </Box>
                    }
                </>
            }

            {
                filteredDocuments.length == 0 &&
                <ListItemText><Typography variant="body2" color="text.secondary">No documents available. Use an app to save your first document.</Typography></ListItemText>
            }
        </MenuList>
    )
};

export default DocumentsList;