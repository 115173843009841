import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Auth0Provider } from '@auth0/auth0-react';
import { RequestProvider } from './contexts/RequestContext';
import { ApiProvider } from './contexts/ApiProvider'; 
import {  createTheme } from '@mui/material';
import bgimg from "./images/pawprints-px.png";
import {CssBaseline, ThemeProvider} from '@mui/material';


const root = ReactDOM.createRoot(document.getElementById('root'));

const AppWrap = ({children}) => {

  const theme = createTheme({
    palette: {
      primary: {
        main: "#ffe200",
        contrastText: "#000000"
      },
      secondary: {
        main: "#222222",
        contrastText: "#ffffff"
      },
      
    },
    typography: {
      button: {
        fontFamily: "Doto",
      },
      fontFamily: "Poppins",
      fontWeight: 100,
      p: {
        fontWeight: 100,
      },
      h5: {
        fontFamily: "Doto",
        fontWeight: 600,
        fontSize: '1.2rem',
      },
      h4: {
        fontFamily: "Doto",
        fontWeight: 600,
        fontSize: '1.4rem',
      },
      h3: {
        fontFamily: "Doto",
        fontWeight: 600,
        fontSize: '1.6rem',
      },
      h2: {
        fontFamily: "Doto",
        fontWeight: 600,
        fontSize: '1.8rem',
      },
      h1: {
        fontSize: '2.3rem', // Customize the font size 
        background: "#ffe200",
        display: 'inline-block',
        padding: '0.5rem',
        lineHeight: 0.6,  // Customize the line height
        fontFamily: "Doto",
        fontWeight: 600,
        color: '#000',    // Customize the color 
        borderRadius: "5px",
        marginBottom: "1rem",
      },
    },
    components: {
  
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            backgroundImage: `url(${bgimg})`, // Set your image path here
            backgroundAttachment: 'fixed',
            backgroundColor: 'purple', // Add your desired color as a fallback
          },
        },
      },
    }
  });
  return(
    <>
    <CssBaseline />
    <ThemeProvider theme={theme}>
      <Auth0Provider
          debug={true}
          domain={process.env.REACT_APP_AUTH0_DOMAIN}
          clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
          redirectUri={ window.location.origin }
          useRefreshTokens={true}
          cacheLocation="localstorage"
          authorizationParams={{
            audience: process.env.REACT_APP_AUTH0_AUDIENCE,
            redirect_uri: window.location.origin,
            scope: "openid profile email offline_access read:current_user",
          }}
      >
        <RequestProvider 
          apiBase={process.env.REACT_APP_HUB_API_URL}
        >
          <ApiProvider>
              <App /> 
          </ApiProvider>
        </RequestProvider>
      </Auth0Provider>
    </ThemeProvider>
    </>
  )
}

root.render(
  <React.StrictMode>
    <AppWrap/>
  </React.StrictMode>
);

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
