import React, { useEffect, useState } from "react";
import { Box, 
    Button, 
    Card, 
    MenuList, 
    CardHeader, 
    CardContent, 
    CircularProgress, 
    ListItemIcon, 
    ListItemText, 
    MenuItem, 
    Stack, 
    Typography } from "@mui/material";
import { AddOrgModal } from "./Organizations/AddOrgModal";
import { useApi } from "../contexts/ApiProvider";
import { useNavigate } from "react-router-dom";

export const SetupWelcome = ({onNext, 
    onPrev}) => {
  const [waiting, 
    setWaiting] = useState(false);
  const {profile, loadProfile, loadCurrentOrgs} = useApi();

  useState(() => {
    setWaiting(true);
    loadProfile();
  }, []);

  // useEffect( () => {
  //   if(profile != null) {
  //     loadProfile();
  //   } else {
  //     setWaiting(false);
  //   }
  // }, [profile]);

  const [addOrgModalOpen, setAddOrgModalOpen] = useState(false);

  const handleOnNext = () => {
    onNext();
  }

  return (
    <Box p={3}>
        <Typography variant="h4">Welcome to Pawsitive Analytics</Typography>

        <Box p={1}>
            <Typography variant="p">
                Pawsitive Analytics is a platform for collecting, analyzing, and reporting on data from your applications. 
                It's designed to help you make better decisions by providing you with the insights you need to make informed choices.
            </Typography>
        </Box>
        <Box p={1}>
            <Typography variant="p">
                We're slowly rolling out the platform to specific organizations and will prompt you to join an existing one
                to gain full functionality of the apps we've built for them.
            </Typography>
        </Box>
        <StepControl nextLoading={profile?.id == null} onNext={() => handleOnNext()} />

    </Box>
  )
};


export const AddOrganization = ({onNext, onPrev}) => {

    const {orgInfoMemo, profile, currentOrgs, loadCurrentOrgs} = useApi();
    const [addOrgModalOpen, setAddOrgModalOpen] = useState(false);  

    console.log("ORG INFO", orgInfoMemo);

    useEffect(() => {
      loadCurrentOrgs();
    },[])

    const handleOnNext = () => {
      onNext();
    }

    const handleOnPrev = () => {
      onPrev();
    }

    return (
      <Stack spacing={2} p={3}>
        <Box>
            <Typography variant="h5">
                Your currently-joined organizations:
            </Typography>
        </Box>
        <Box>
            <Card>
                <CardContent>
                {
                    orgInfoMemo?.organizations.length == 0 &&   
                    <Typography variant="p" color={"text.secondary"}>
                        You haven't joined any organizations yet. Click the button below to join one.
                    </Typography>
                }
            
                {
                    orgInfoMemo?.organizations.map((org) => {
                        return (
                            <MenuItem key={org.id}>
                                <ListItemIcon>
                                    <Box component={'img'} src={org.logo_url} sx={{width:'50px', height:'50px'}}/>
                                </ListItemIcon>
                                <ListItemText>{org.name}</ListItemText>
                            </MenuItem>
                        )
                    })
                }
                </CardContent>
            </Card>
        </Box>
        <Button color="primary" variant="contained" onClick={() => setAddOrgModalOpen(true)}>Join { orgInfoMemo?.organizations.length > 0 ? "Another" : "An" } Organization</Button>
        <AddOrgModal onOpen={() => setAddOrgModalOpen(true)} onClose={() => setAddOrgModalOpen(false)} open={addOrgModalOpen}/>  
        <StepControl onPrev={() => handleOnPrev()} nextDisabled={orgInfoMemo?.organizations.length < 1} onNext={() => handleOnNext()}/>
        
      </Stack>
    )
};

export const AddApps = ({onPrev, onNext}) => {
    const {orgInfoMemo} = useApi();
    const handleOnNext = () => {
      onNext();
    }

  const handleOnPrev = () => {
      onPrev();
    }

    return (
        <Stack my={3} spacing={2}>
        {
            orgInfoMemo?.organizations.map((org) => {
                return (
                    <Stack key={org.id} spacing={2}>
                        <Card>
                            <CardHeader
                                title={<Stack direction="row" spacing={5} alignItems={'center'}>
                                            <Box component={'img'} src={org.logo_url} sx={{width:'45px', height:'45px'}}/>
                                            <Box>{org.name}</Box>
                                        </Stack>
                                }>
                            </CardHeader>
                            <CardContent>
                                <MenuList>
                                { org?.service_apps.map((app) => { 
                                    return(
                                        <MenuItem direction="row" spacing={2}>
                                            <ListItemIcon>
                                                <Box component="img" src={app.icon_url} sx={{width:'50px', height:'50px'}}/>
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={app.name}
                                                secondary={app.description}
                                            />
                                        </MenuItem>
                                    )
                                })}
                                </MenuList>
                            </CardContent>
                        </Card>
                        
                        <StepControl onPrev={() => handleOnPrev()} nextDisabled={orgInfoMemo?.organizations.length < 1} onNext={() => handleOnNext()}/>
                    </Stack>
                )
            })
        }
        
      </Stack>
    )
};

export const VerifyComplete = () => {
    return (
      <Box p={3}>
        <Typography variant="h4">Welcome to Pawsitive Analytics</Typography>

        <Typography variant="p">
            Pawsitive Analytics is a platform for collecting, analyzing, and reporting on data from your applications. 
            It's designed to help you make better decisions by providing you with the insights you need to make informed choices.
        </Typography>
      </Box>
    )
};


export const FinishSetup = () => {
    let navigate = useNavigate();
    const {loadProfile} = useApi();
    return (
      <Stack spacing={2} p={3}>
        <Typography variant="h4">You're All Done!</Typography>

        <Typography variant="p">
            You're ready to log in and start using the Pawsitive Analytics Hub. Click the button below to continue.
        </Typography>

        <Button variant="contained" color="primary" onClick={() => loadProfile()}>Go to Dashboard</Button>

        {/* <StepControl onNext={() => navigate('/dashboard')}/> */}
      </Stack>
    )
};


const StepControl = ({onNext, onPrev, prevDisabled=false, nextDisabled=false, nextLoading=false})  => {

  {/* Action Buttons */}
  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
      {
            onPrev != null &&
            <Button
                color="inherit"
                disabled={prevDisabled}
                onClick={onPrev}
                sx={{ mr: 1 }}
            >
                Back
            </Button>
      }
      
      <Box sx={{ flex: '1 1 auto' }} />
      {
        nextLoading &&
        <CircularProgress
            size={24}
        />
      }
      {
            !nextLoading && onNext != null &&
            <Button
                color="inherit"
                disabled={nextDisabled}
                onClick={onNext}
                sx={{ mr: 1 }}
            >
                Next
            </Button>
      }
      
    </Box>
  )
}