import React, { Component, useEffect, useMemo, useState } from 'react';
import '../App.css';
import {useMediaQuery, useTheme, Box, Button, Card, CardContent, CardHeader, CardMedia, Container, Divider, Grid, Paper, Stack, Typography } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import AuthChecker from './AuthChecker';
import { useRequests } from '../contexts/RequestContext';
import image from './images/backdrop.jpg'
import Dashboard from './Dashboard';
import { NavLink } from 'react-router-dom';
import CompleteProfilePrompts from './Auth/CompleteProfilePrompts';
import { useApi } from '../contexts/ApiProvider';
/*
*/

const CompleteProfileInterjection = ({account}) => {
  const [reports, setReports] = useState([]);
  
  const theme = useTheme();
  const bigEnough = useMediaQuery(theme.breakpoints.up('sm'));

  
  const {user, isAuthenticated, loginWithRedirect, logout} = useAuth0();
  const {authenticatedFetch} = useRequests();
  
  const {orgInfo, documents, applications, profile, loadProfile, loadCurrentOrgs} = useApi();

  useEffect( () => {
    loadProfile();
    loadCurrentOrgs();
  },[])


//   const {orgInfo} = useApi();

  return (
      <Container mt={'50px'} maxWidth="md">
        {
            (true || orgInfo.organizations.length == 0 )&&
            <Card sx={{display:'flex', flexDirection:bigEnough ? 'row' : 'column'}}>
                {
                  bigEnough &&
                  <CardMedia
                      component="img"
                      media="picture"
                      alt="Actual unicorn"
                      sx={{display:'block', position:'relative', minWidth:'100px', maxWidth:'5%', minHeight: '500px'}}
                      image={image}
                  />
                }
                {
                  !bigEnough &&
                  <CardMedia
                      component="img"
                      media="picture"
                      alt="Actual unicorn"
                      sx={{display:'block', position:'relative', height: '150px'}}
                      image={image}
                  />
                }
                <CardContent sx={{flex: 1, alignContent: 'center', display:'block', alignText:'left'}}>
                    {
                        isAuthenticated && user &&
                        <Stack spacing={2}>
                            <CompleteProfilePrompts/>
                        </Stack>
                    }
                </CardContent>
            </Card>
        }
    </Container>
  );
}

export default CompleteProfileInterjection;